import React from 'react'
import getProjects from '../../helpers/getProjects'
import placeholderImage from '../../assets/images/project-preview-placeholder.png'
import { Link } from 'react-router-dom'
import { Loader } from '../loader/Loader'
import { getErrorMessage } from '../../helpers/getErrorMessage'
import { Error } from '../error/Error'
import './Projects.css'

export const projectsUrl = '/projects'
export class Projects extends React.Component {

    constructor() {
        super()
        this.state = {
            loaded: false,
            projects: [],
            error: false,
            errorMessage: ''
        }
    }

    render() {
        return <div className="projects">
            {this.renderContent()}
        </div>
    }

    renderContent() {
        if (this.state.loaded && this.state.error) {
            return <Error message={this.state.errorMessage} />
        } else if (this.state.loaded) {
            return this.state.projects.map(p => <Link to={`/projects/${p.id}`} key={p.id}>
                <img className="project-preview" alt={p.name} src={`/images/${p.previewImageUrl}`} />
            </Link>)
        } else {
            return <>
                <Loader></Loader>
                <img className="project-preview" alt="Placeholder 1" key={0} src={placeholderImage} />
                <img className="project-preview" alt="Placeholder 2" key={1} src={placeholderImage} />
                <img className="project-preview" alt="Placeholder 3" key={2} src={placeholderImage} />
            </>
        }
    }

    componentDidMount() {
        getProjects().then(projects => {
            this.setState({
                loaded: true,
                projects
            })
        }).catch(err => {
            this.setState({
                loaded: true,
                error: true,
                errorMessage: getErrorMessage(err)
            })
        })
    }
}
