let cached = null

export default function getReels() {
    if (cached != null) {
        return Promise.resolve(cached)
    } else {
        return new Promise((resolve, reject) => {
            fetch('/data/reels.json')
                .then(r => {
                    let reels = r.json()
                    cached = reels
                    resolve(reels)
                }).catch(err => reject(err))
        })
    }

}
