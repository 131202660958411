export function getErrorMessage(error) {
    if (error == null) {
        return 'An unknown error has occurred :('
    } else if (typeof error === 'string') {
        return error
    } else if (error.message != null) {
        return error.message
    } else {
        return JSON.stringify(error)
    }
}
