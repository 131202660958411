import soundcloudLogo from '../../assets/images/soundcloud-logo.png'
import audiusLogo from '../../assets/images/audius-logo.png'
import twitterLogo from '../../assets/images/twitter-logo.png'
import itchLogo from '../../assets/images/itch-logo.png'
import youtubeLogo from '../../assets/images/youtube-logo.png'
import './Socials.css'

export const socialsUrl = '/socials'
export function Socials() {
    return <div className="socials">
        <a href="https://www.youtube.com/channel/UCcZDR1j5DoSMOlVXSVoDpcQ">
            <img src={youtubeLogo} className="socials-logo" alt="YouTube" />
        </a>
        <a href="https://soundcloud.com/kugutsutsukai">
            <img src={soundcloudLogo} className="socials-logo" alt="Soundcloud" />
        </a>
        <a href="https://audius.co/testmusicaccount">
            <img src={audiusLogo} className="socials-logo" alt="Audius" />
        </a>
        <a href="https://twitter.com/kugutsupasta">
            <img src={twitterLogo} className="socials-logo" alt="Twitter" />
        </a>
        <a href="https://puppetpasta.itch.io/">
            <img src={itchLogo} className="socials-logo" alt="Itch.io" />
        </a>
    </div>
}
