import React from 'react'
import getTestimonials from '../../helpers/getTestimonials'
import { getErrorMessage } from '../../helpers/getErrorMessage'
import { Loader } from '../loader/Loader'
import './Testimonials.css'
import { Error } from '../error/Error'

export const testimonialsUrl = '/testimonials'
export class Testimonials extends React.Component {

    constructor() {
        super()
        this.state = {
            loaded: false,
            testimonials: [],
            error: false,
            errorMessage: ''
        }
    }

    render() {
        return <div className="testimonials">
            {this.renderContent()}
        </div>
    }

    renderContent() {
        if (!this.state.loaded) {
            return <Loader></Loader>
        } else if (this.state.loaded && this.state.error) {
            return <Error message={this.state.errorMessage} />
        } else {
            return this.state.testimonials.map(t => <blockquote className="testimonials-quote" key={t.author}>
                <p className="testimonials-quote-text">{t.text}</p>
                <p className="testimonials-quote-author">{t.author}</p>
            </blockquote>)
        }
    }

    componentDidMount() {
        getTestimonials().then(t => {
            this.setState({
                loaded: true,
                testimonials: t
            })
        }).catch(err => {
            this.setState({
                loaded: true,
                error: true,
                errorMessage: getErrorMessage(err)
            })
        })
    }
}
