let cached = null

export default function getProjects() {
    if (cached != null) {
        return Promise.resolve(cached)
    } else {
        return new Promise((resolve, reject) => {
            fetch('/data/projects.json')
                .then(r => {
                    let projects = r.json()
                    cached = projects
                    resolve(projects)
                }).catch(err => reject(err))
        })
    }

}
