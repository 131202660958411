import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { Navigator } from './components/navigator/Navigator'
import { Projects, projectsUrl } from './components/projects/Projects'
import { Project, projectUrl } from './components/project/Project'
import { Socials, socialsUrl } from './components/socials/Socials'
import { Reels, reelsUrl } from './components/reels/Reels'
import { Error } from './components/error/Error'
import { Testimonials, testimonialsUrl } from './components/testimonials/Testimonials'
import './App.css'

function App() {
    return (
        <div id="App">
            <BrowserRouter>
                <Navigator></Navigator>
                <div id="RouterTarget">
                    <Switch>
                        <Route exact path={projectsUrl}>
                            <Projects></Projects>
                        </Route>
                        <Route exact path={socialsUrl}>
                            <Socials></Socials>
                        </Route>
                        <Route exact path={testimonialsUrl}>
                            <Testimonials></Testimonials>
                        </Route>
                        <Route exact path={reelsUrl}>
                            <Reels></Reels>
                        </Route>
                        <Route path={projectUrl} render={props => <Project id={props.match.params.id}></Project>} />
                        <Redirect exact from="/" to={projectsUrl} />
                        <Route exact path='*'>
                            <Error message="Sorry, this page doesn't exist..."></Error>
                        </Route>
                    </Switch>
                </div>
            </BrowserRouter>
        </div>
    )
}

export default App
