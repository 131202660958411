import React from 'react'
import { Error } from '../error/Error'
import './Embeddable.css'

export class Embeddable extends React.Component {

    render() {
        let showTitle = this.props.data.title != null

        return <div className="embeddable">
            {showTitle ? <h4>{this.props.data.title}</h4> : <h4></h4>}
            {this.renderContent()}
        </div>
    }

    renderContent() {
        switch (this.props.data.type) {
            case 'SOUNDCLOUD_PLAYLIST':
                return this.renderSoundcloudPlaylist(this.props.data.playlistId)
            case 'SOUNDCLOUD_TRACK':
                return this.renderSoundcloudTrack(this.props.data.trackId, this.props.data.secretToken)
            case 'AUDIUS_PLAYLIST':
                return this.renderAudiusPlaylist(this.props.data.playlistId)
            case 'YOUTUBE_VIDEO':
                return this.renderYoutubeVideo(this.props.data.videoId)
            case 'ITCH_IO_GAME':
                return this.renderItchIoGame(this.props.data.gameId, this.props.data.gameTitle, this.props.data.gameUrl)
            default:
                return <Error message="Unsupported embeddable type :(" />
        }
    }

    renderSoundcloudPlaylist(playlistId) {
        let title = `${this.props.data.type}_${playlistId}`
        return <iframe title={title} width="100%" height="450"
            scrolling="no" frameBorder="no" allow="autoplay"
            src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/${playlistId}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=false`}>
        </iframe>
    }

    renderSoundcloudTrack(trackId, secretToken) {
        let title = `${this.props.data.type}_${trackId}`
        let idBit = secretToken != null ? `${trackId}%3Fsecret_token%3D${secretToken}&` : `${trackId}%3F`
        return <iframe title={title} width="100%" height="166"
            scrolling="no" frameBorder="no" allow="autoplay"
            src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${idBit}color=%23ff5500&inverse=false&auto_play=false&show_user=true`}>
        </iframe>
    }

    renderAudiusPlaylist(playlistId) {
        let title = `${this.props.data.type}_${playlistId}`
        return <iframe title={title} width="100%" height="480px"
            allow="encrypted-media" frameBorder="no"
            src={`https://audius.co/embed/playlist/${playlistId}?flavor=card`}></iframe>
    }

    renderYoutubeVideo(videoId) {
        let title = `${this.props.data.type}_${videoId}`
        return <iframe title={title} width="560" height="315"
            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen
            src={`https://www.youtube.com/embed/${videoId}`}>
        </iframe>
    }

    renderItchIoGame(id, name, url) {
        let title = `${this.props.data.type}_${id}`
        return <iframe title={title} height="167" width="552" frameBorder="0" src={`https://itch.io/embed/${id}`} >
            <a href={url}>{name}</a>
        </iframe>
    }
}
