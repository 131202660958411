import React from 'react'
import getReels from '../../helpers/getReels'
import { getErrorMessage } from '../../helpers/getErrorMessage'
import placeholderImage from '../../assets/images/project-banner-placeholder.png'
import { Loader } from '../loader/Loader'
import { Embeddable } from '../embeddable/Embeddable'
import { Error } from '../error/Error'
import './Reels.css'

export const reelsUrl = '/reels'
export class Reels extends React.Component {

    constructor() {
        super()
        this.state = {
            loaded: false,
            reels: [],
            error: false,
            errorMessage: ''
        }
    }

    render() {
        return <div className="embeddables">
            {this.renderContent()}
        </div>
    }

    renderContent() {
        if (this.state.loaded && this.state.error) {
            return <Error message={this.state.errorMessage} />
        } else if (this.state.loaded) {
            return this.state.reels.map((r, i) => <Embeddable data={r} key={i}></Embeddable>)
        } else {
            return <>
                <Loader></Loader>
                <img className="embeddable-placeholder" alt="Placeholder 1" key={0} src={placeholderImage} />
            </>
        }
    }

    componentDidMount() {
        getReels().then(reels => {
            this.setState({
                loaded: true,
                reels
            })
        }).catch(err => {
            this.setState({
                loaded: true,
                error: true,
                errorMessage: getErrorMessage(err)
            })
        })
    }
}
