import React from 'react'
import { Remarkable } from 'remarkable'
import getProjects from '../../helpers/getProjects'
import bannerPlaceholderImage from '../../assets/images/project-banner-placeholder.png'
import { Embeddable } from '../embeddable/Embeddable'
import { Loader } from '../loader/Loader'
import { getErrorMessage } from '../../helpers/getErrorMessage'
import { Error } from '../error/Error'
import './Project.css'

export const projectUrl = '/projects/:id'
export class Project extends React.Component {

    constructor() {
        super()
        this.state = {
            loaded: false,
            project: {},
            error: false,
            errorMessage: ''
        }
    }

    render() {
        return <div className="project">
            {this.renderContent()}
        </div>
    }

    renderContent() {
        if (this.state.loaded && this.state.error) {
            return <Error message={this.state.errorMessage} />
        } else if (this.state.loaded) {
            return <>
                <img className="project-banner" alt={this.state.project.name} src={`/images/${this.state.project.bannerImageUrl}`} />
                {this.renderRoles()}
                {this.renderDescription()}
                {this.renderEmbeddables()}
            </>
        } else {
            return <>
                <Loader></Loader>
                <img className="project-banner" alt="Loading" src={bannerPlaceholderImage} />
            </>
        }
    }

    renderDescription() {
        if (this.state.project.description != null) {
            let markdownHtml = new Remarkable().render(this.state.project.description)
            return <div className="project-description" dangerouslySetInnerHTML={{ __html: markdownHtml }}></div>
        } else {
            return <></>
        }
    }

    renderRoles() {
        if (this.state.project.roles != null && this.state.project.roles.length > 0) {
            let roleListAsString = this.state.project.roles.reduce((a, b) => `${a}, ${b}`),
                role = this.state.project.roles.length === 1 ? 'role' : 'roles'

            return <p className="project-roles">
                My {role} in this project: {roleListAsString}
            </p>
        } else {
            return <></>
        }
    }

    renderEmbeddables() {
        if (this.state.project.embeddables != null) {
            return this.state.project.embeddables.map((r, i) => <Embeddable data={r} key={i}></Embeddable>)
        } else {
            return <></>
        }
    }

    componentDidMount() {
        getProjects().then(projects => {
            let project = projects.find(p => p.id === this.props.id)

            if (project != null) {
                this.setState({
                    loaded: true,
                    project: project
                })
            } else {
                this.setState({
                    loaded: true,
                    error: true,
                    errorMessage: `No project found with ID ${this.props.id}`
                })
            }
        }).catch(err => {
            this.setState({
                loaded: true,
                error: true,
                errorMessage: getErrorMessage(err)
            })
        })
    }
}
