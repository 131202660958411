import { Link, useLocation } from 'react-router-dom'
import { projectsUrl } from '../projects/Projects'

import './Navigator.css'

export const navigatorUrl = '/'
export function Navigator() {
    let currentPath = useLocation().pathname

    return (
        <div id="Navigator">
            {getItems().filter(i => i.active).map(item => {
                let className = `navigator-item ${currentPath.indexOf(item.path) === 0 ? 'highlight' : ''}`
                return <Link to={item.path} key={item.path}>
                    <button className={className}>
                        {item.label}
                    </button>
                </Link>
            })}
        </div>
    )
}

function getItems() {
    return [
        { label: 'Projects', path: projectsUrl, active: true },
        { label: 'Socials', path: '/socials', active: true },
        { label: 'Reels', path: '/reels', active: true },
        { label: 'Testimonials', path: '/testimonials', active: false }
    ]
}
